import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import { catchError, finalize } from 'rxjs/operators';
import Swal from "sweetalert2";
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  totalRequests = 0;
  constructor(private loadingService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request body contains a password field
    // console.log(request)
    if (request.body && request.body.password) {
      // console.log(request.body)
      // Encode the password field using encodeURIComponent
      const encodedPassword = encodeURIComponent(request.body.password);

      // Clone the request and replace the password with the encoded value
      request = request.clone({
        body: { ...request.body, password: encodedPassword }
      });
    }
    if (request.url.includes('password')) {
      // Encode the password parameter if found
      const encodedUrl = request.url.replace(/password=([^&]*)/, (match, password) => {
        return `password=${encodeURIComponent(password)}`;
      });

      // Clone the request with the modified URL
      request = request.clone({ url: encodedUrl });
    }
    // Exclude certain URLs from interception
    if (
      request.url.includes('addoapi/addoOnlineOrder/list') ||
      request.url.includes('rider/getAddress_new') ||
      request.url.includes('addoapi/riderAllocation/getRiderDetails_web') ||
      request.url.includes('/flash_admin/getRiderSummary/getRiderDetails') ||
      request.url.includes('/crm_api/get/tg/details') ||
      request.url.includes('/rider/getActiveTask_new') ||
      request.url.includes('/get-ongoing-tasks')||
      request.url.includes('/addoapi/getQRCodeTypes')
    ) {
      return next.handle(request); // Don't intercept, just proceed with the request
    }

    this.totalRequests++;
    // Show loading indicator
    this.loadingService.show();

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          // Hide loading indicator when all requests are complete
          this.loadingService.hide();
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.handleError(err);
        return throwError(() => err);
      })
    );

  }
  private handleError(err: HttpErrorResponse): void {
    if (err) {
      switch (err.status) {
        case 403:
          Swal.fire('', err.error.message, 'error')
          break;
        case 500:
          Swal.fire('', err.error.message, 'error')
          break;
        // case 400:
        //   alert('Bad Request');
        //   break;
        // case 401:
        //   alert('Unauthorized');
        //   break;
        // case 404:
        //   alert('Not Found');
        //   break;
        // default:
        //   alert('An error occurred');
        //   break;
      }
    }
  }
}
