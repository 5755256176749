// Angular
import { NgModule } from '@angular/core';
// import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
// Components
// import { BaseComponent } from "./views/theme/base/base.component";
// Auth
// import { AuthGuard } from "./core/auth";
import { AuthGuardService } from './auth-guard-service.service';
import { LoginGuard } from './login.guard';
import { ApprovalPendingComponent } from './views/pages/lazy/approval-pending/approval-pending.component';
import { BrandregisterComponent } from './views/pages/lazy/brandregister/brandregister.component';
import { BusinessSelectorComponent } from './views/pages/lazy/businessSelector/businessSelector.component';
import { CreateBusinessComponent } from './views/pages/lazy/create-business/create-business.component';
import { EdgeCreationComponent } from './views/pages/lazy/edge-creation/edge-creation.component';
// import { ErrorPageComponent } from "./views/pages/lazy/error-page/error-page.component";
// import { PrintOnlineComponent } from "./views/pages/lazy/printOnlineOrder/printOnlineOrder.component";
import { POSComponent } from './views/pages/lazy/pos/pos.component';
import { LogoutComponent } from './views/pages/lazy/logout/logout.component';
import { FullScreenLiveRiderComponent } from './views/pages/lazy/full-screen-live-rider/full-screen-live-rider.component';
// import { UserProfileManagement } from './views/pages/lazy/userProfileManagement/userProfile.component';
// import { DetailedUserProfile } from './views/pages/lazy/detailedUserProfile/detailedUserProfile.component';
// import { OrderCardComponent } from './views/pages/order-card/order-card.component';
// import { BrandComponent } from "./views/theme/brand/brand.component";
// import { LoginComponent } from "./views/pages/auth/login/login.component";
// import { OnboardingComponent } from "./views/pages/lazy/onboarding/onboarding.component";

// import { onlineOrderComponent } from "./views/pages/components/onlineOrder/onlineOrder.component";
// import { OrderInfoComponent } from "./views/pages/components/orderInfo/orderInfo.component";

// import { RiderManagementComponent } from "./views/pages/components/riderManagement/riderManagement.component";
// import { RiderPastOrders } from "./views/pages/components/riderPastOrders/riderPastOrders.component";
// import { MenuManagementComponent } from "./views/pages/components/menuManagement/menuManagement.component";
// import { MenuItemManagementComponent } from "./views/pages/components/menuItemManagement/menuItemManagement.component";
// import { MarketPlaceComponent } from "./views/pages/components/market-place/market-place.component";
// import { SafetyMeasureComponent } from "./views/pages/components/safetyMeasure/safetyMeasure.component";
// import { CampaignManagementComponent } from "./views/pages/components/campaign-management/campaign-management.component";
// import { BulkInventoryUpdateComponent } from "./views/pages/components/bulkInventoryUpdate/bulkInventoryUpdate.component";
// import { DashboardComponent } from "./views/pages/components/graphDashboard/dashboard.component";
// import { ImageManagementComponent } from "./views/pages/components/imageManagement/imageManagement.component";
// import { PaymentComponent } from "./views/pages/components/payments/payments.component";
// import { Payment_BreakupComponent } from "./views/pages/components/payment_breakup/payment_breakup.component";
// import { PrintComponent } from "./views/pages/components/print/print.component";
// import { PromoManagementComponent } from "./views/pages/components/promoManagement/promoManagement.component";
// import { PromoDetailsComponent } from "./views/pages/components/promoDetails/promoDetails.component";
// import { UserProfileManagement } from "./views/pages/components/userProfileManagement/userProfile.component";
// import { DetailedUserProfile } from "./views/pages/components/detailedUserProfile/detailedUserProfile.component";
// import { LoyaltyComponent } from "./views/pages/components/loyalty/loyalty.component";
// import { PushNotificationComponent } from "./views/pages/components/pushNotification/pushNotification.component";
// import { StoreManagementComponent } from "./views/pages/components/storeManagement/storeManagement.component";
// import { StoreInfoComponent } from "./views/pages/components/storeInfo/storeInfo.component";
// // import { ViewStoreComponent } from "./views/pages/components/viewStore/viewStore.component";
// import { BannerManagementComponent } from "./views/pages/components/bannerManagement/bannerManagement.component";
// import { DocumentationManagerComponent } from "./views/pages/components/documentationManager/documentationManager.component";
// import { AddDocumentComponent } from "./views/pages/components/addDocumentation/addDocumentation.component";
// import { BlogManagerComponent } from "./views/pages/components/blogManager/blogManager.component";
// import { AddBlogComponent } from "./views/pages/components/addBlog/addBlog.component";
// import { ShowDocumentComponent } from "./views/pages/components/showDocumentation/showDocumentation.component";
// import { InvoiceComponent } from "./views/pages/components/invoices/invoices.component";
// import { LeadGenerationFormComponent } from "./views/pages/components/leadGenerationForm/leadGenerationForm.component";
// import { ViewLeadComponent } from "./views/pages/components/viewLead/viewLead.component";
// import { AddLeadFieldsComponent } from "./views/pages/components/addLeadFormFields/addLeadFields.component";
// import { Error6Component } from "./views/pages/error/error6/error6.component";
// import { FeedManagementComponent } from "./views/pages/components/feedManagement/feedManagement.component";
// import { ReportsComponent } from "./views/pages/components/reporting/reports.component";
// import { OrderComponent } from "./views/pages/components/orders/order.component";

// import { DeliveryLogisticsComponent } from "./views/pages/components/deliveryLogistics/deliveryLogistics.component";
// import { CrmComponent } from './views/pages/components/crm/crm.component';
// import { SegmentsComponent } from './views/pages/components/segments/segments.component';
// import {ReportComponent} from './views/pages/components/cutomer_review/report.component';
// import { from } from "rxjs";
// import {CanDeactivateGuard} from './can-deactivate/can-deactivate.guard';
// import { RequestDetailsComponent } from './views/pages/components/request-details/request-details.component';
// import { RequestInventoryComponent } from './views/pages/components/request-inventory/request-inventory.component';
// import { RequestItemComponent } from './views/pages/components/request-item/request-item.component';
// import { RequestManagementComponent } from './views/pages/components/request-management/request-management.component';
// import { MessFlowComponent } from './views/pages/components/mess-flow/mess-flow.component';
// import { UpdateInventoryComponent } from './views/pages/components/update-inventory/update-inventory.component';
// import { WebConfigComponent } from './views/pages/components/web-config/web-config.component';
// import { LogoutComponent } from './views/pages/components/logout/logout.component';
// import { MapModuleComponent } from "./views/pages/components/map-module/map-module.component";
// import { ViewStoreComponent } from "./views/pages/components/viewStore/viewStore.component";
// import { NpsModuleComponent } from "./views/pages/components/nps-module/nps-module.component";
// import { OnboardingComponent } from "./views/pages/components/onboarding/onboarding.component";
// import { ViewStoreComponent } from "./views/pages/components/viewStore/viewStore.component";

const routes: Routes = [
  {
    path: 'complete-flash-profile',
    component: BrandregisterComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: 'complete-edge-profile',
    component: EdgeCreationComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: 'approval-pending',
    component: ApprovalPendingComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: 'create-brand',
    component: CreateBusinessComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: 'businessSelector',
    component: BusinessSelectorComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: 'pos/:orderFrom/:orderId',
    component: POSComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'uEngage Dashboard',
      // title: 'Flash dashboard-uEngage Dashboard',
      ogTitle: 'POS-uEngage Dashboard',
    },
  },
  {
    path: "live-rider-view",
    component: FullScreenLiveRiderComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'uEngage Dashboard',
      // title: 'Flash dashboard-uEngage Dashboard',
      ogTitle: 'Live Rider View-uEngage Dashboard',
    }
  },
  // {
  //   path: "printOnlineOrder/:orderId",
  //   component: PrintOnlineComponent,
  // },
  // {
  //   path: "print/:orderId",
  //   component: PrintComponent,
  // },
  // {s

  //   path: "business-selector",
  //   loadChildren: () =>
  //     import("./views/pages/business-selector/business-selector.module").then(
  //       (m) => m.BusinessSelectorModule
  //     ),

  // },

  {
    path: 'auth',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoginGuard],
  },

  // {
  //   path: "error",
  //   loadChildren: () =>
  //     import("./views/pages/error/error.module").then((m) => m.ErrorModule),
  // },
  {
    path: 'Dashboard',
    loadChildren: () =>
      import('./views/pages/lazy/lazy-routing.module').then(
        (m) => m.LazyRoutingModule
      ),
    // canActivate: [AuthGuardService],
  },

  // {
  //   path: "pos/:orderFrom/:orderId",
  //   component: POSComponent,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: "Dashboard",
  //   component: BaseComponent,
  //   //canActivate: [AuthGuardService],
  //   children: [
  // {
  //   path: "dashboard",
  //   loadChildren: () =>
  //     import("./views/pages/dashboard/dashboard.module").then(
  //       (m) => m.DashboardModule
  //     ),
  // },
  // {
  //   path: "mail",
  //   loadChildren: () =>
  //     import("./views/pages/apps/mail/mail.module").then(
  //       (m) => m.MailModule
  //     ),
  // },
  // {
  //   path: "ecommerce",
  //   loadChildren: () =>
  //     import("./views/pages/apps/e-commerce/e-commerce.module").then(
  //       (m) => m.ECommerceModule
  //     ),
  // },
  // {
  //   path: "ngbootstrap",
  //   loadChildren: () =>
  //     import("./views/pages/ngbootstrap/ngbootstrap.module").then(
  //       (m) => m.NgbootstrapModule
  //     ),
  // },
  // {
  //   path: "material",
  //   loadChildren: () =>
  //     import("./views/pages/material/material.module").then(
  //       (m) => m.MaterialModule
  //     ),
  // },
  // {
  //   path: "user-management",
  //   loadChildren: () =>
  //     import("./views/pages/user-management/user-management.module").then(
  //       (m) => m.UserManagementModule
  //     ),
  // },
  // {
  //   path: "wizard",
  //   loadChildren: () =>
  //     import("./views/pages/wizard/wizard.module").then(
  //       (m) => m.WizardModule
  //     ),
  // },
  // {
  //   path: "builder",
  //   loadChildren: () =>
  //     import("./views/theme/content/builder/builder.module").then(
  //       (m) => m.BuilderModule
  //     ),
  // },
  // {
  //   path: "lazy",
  //   loadChildren: () =>
  //   import("./views/pages/lazy/lazy.module").then((m) => m.LazyModule),
  //   canActivate: [AuthGuardService],
  // },
  //   {
  //     path: "onboarding",
  //     component: OnboardingComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "orderInfo/:orderId",
  //     component: OrderInfoComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "maps-locality",
  //     component: MapModuleComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "marketingAutomation",
  //     component: CrmComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "segments",
  //     component: SegmentsComponent,
  //     canDeactivate: [CanDeactivateGuard],
  //   },
  //   {
  //     path: "npsModule",
  //     component: NpsModuleComponent,
  //     canActivate:[AuthGuardService]
  //   },
  //   {
  //     path: "mess-flow",
  //     component: MessFlowComponent,
  //     canActivate:[AuthGuardService]
  //   },
  //   {
  //     path:"updateInventory",
  //     component: UpdateInventoryComponent,
  //     canDeactivate:[CanDeactivateGuard],
  //   },

  //   {
  //     path: "web_config",
  //     component: WebConfigComponent,
  //     canActivate: [AuthGuardService],
  //   },

  //   // {
  //   //   path: "printOnlineOrder/:orderId",
  //   //   component: PrintOnlineComponent,

  //   // },
  //   {
  //     path: "riderManagement",
  //     component: RiderManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path :"feed-management",
  //     component:FeedManagementComponent,
  //     canActivate: [AuthGuardService]
  //   },
  //   {
  //     path: "delivery-logistic",
  //     component: DeliveryLogisticsComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "riderManagement/details/:riderId",
  //     component: RiderPastOrders,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "menuManagement",
  //     component: MenuManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "menuManagement/menuItemManagement/:sectionId/:sectionType",
  //     component: MenuItemManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "market-place",
  //     component: MarketPlaceComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "request-items",
  //     component: RequestItemComponent,
  //     canDeactivate:[CanDeactivateGuard],
  //   },
  // {
  //     path: "request-inventory",
  //     component: RequestInventoryComponent,
  //     canActivate :[AuthGuardService],
  //   },
  //   {
  //     path:"request-details/:requestId/:flag",
  //     component:RequestDetailsComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "request-management",
  //     component: RequestManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "safety-measure",
  //     component: SafetyMeasureComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "campaign-management",
  //     component: CampaignManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "bulk-inventory-update",
  //     component: BulkInventoryUpdateComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "dashboard",
  //     component: DashboardComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "imageManagement",
  //     component: ImageManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "customer_review",
  //     component: ReportComponent,
  //     canActivate: [AuthGuardService],
  //   },

  //   {
  //     path: "payments",
  //     component: PaymentComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "payments/payment-breakup/:pId",
  //     component: Payment_BreakupComponent,
  //     canActivate: [AuthGuardService],
  //   },

  //   {
  //     path: "undefined",
  //     component: LogoutComponent,
  // },

  //   // {
  //   //   path: "print/:orderId",
  //   //   component: PrintComponent,

  //   // },
  //   {
  //     path: "promoManagement",
  //     component: PromoManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "promoManagement/promoDetails/:promoCodeId",
  //     component: PromoDetailsComponent,
  //     canActivate: [AuthGuardService],
  //   },
  // {
  //   path: "userProfile",
  //   component: UserProfileManagement,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: "userProfile/detailedUserProfile/:userId",
  //   component: DetailedUserProfile,
  //   canActivate: [AuthGuardService],
  // },
  //   {
  //     path: "loyaltyDashboard",
  //     component: LoyaltyComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "pushNotification",
  //     component: PushNotificationComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "storeManagement",bannersManagement
  //     component: StoreManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "storeInfo",
  //     component: StoreInfoComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "storeManagement/viewStore/:storeId",
  //     component: ViewStoreComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "bannersManagement",
  //     component: BannerManagementComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "manage-documentation",
  //     component: DocumentationManagerComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "manage-documentation/documents/:documentId",
  //     component: AddDocumentComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "blog-manager",
  //     component: BlogManagerComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "blog-manager/addBlog/:blogId",
  //     component: AddBlogComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "showDocumentation/:documentId",
  //     component: ShowDocumentComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "invoices",
  //     component: InvoiceComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "leadGenerationForm",
  //     component: LeadGenerationFormComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "leadGenerationForm/viewLead/:leadId/:migratedFlag",
  //     component: ViewLeadComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "leadGenerationForm/addFields/:formId",
  //     component: AddLeadFieldsComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "feed-management",
  //     component: Error6Component,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "reports",
  //     component: ReportsComponent,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "market-place",
  //     component: Error6Component,
  //     canActivate: [AuthGuardService],
  //   },
  //   {
  //     path: "order",
  //     component: OrderComponent,
  //     canActivate: [AuthGuardService],
  //   },
  // ],
  // },
  // { path: "", redirectTo: "auth", pathMatch: "full" },a
  {
    path: '',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoginGuard],
  },

  {
    path: 'flash/flash-dashboard',
    loadChildren: () =>
      import(
        './views/pages/flash-dashboard/flash-dashboard-routing.module'
      ).then((m) => m.FlashDashboardRoutingModule),
  },
    {
    path: 'flash/flash-dboard',
    loadChildren: () =>
      import(
        './views/pages/flash-dashboard/flash-dashboard-routing.module'
      ).then((m) => m.FlashDashboardRoutingModule),
  },
  // {
  //   path: 'flash/flash-summary/:id',
  //   loadChildren: () =>
  //     import('./views/pages/flash-summary/flash-summary-routing.module').then(
  //       (m) => m.FlashSummaryRoutingModule
  //     ),
  // },
  {
    path: 'flash/flash-summary',
    loadChildren: () =>
      import('./views/pages/flash-summary/flash-summary-routing.module').then(
        (m) => m.FlashSummaryRoutingModule
      ),
  },
  {
    path: 'flash/flash-configuration',
    loadChildren: () =>
      import(
        './views/pages/flash-configuration/flash-configuration-routing.module'
      ).then((m) => m.FlashConfigurationRoutingModule),
  },
  {
    path: 'flash/rider-management',
    loadChildren: () =>
      import(
        './views/pages/rider-management/rider-management-routing.module'
      ).then((m) => m.RiderManagementRoutingModule),
  },
  {
    path: 'prism/marketingAutomation',
    loadChildren: () =>
      import('./views/pages/crm/crm-routing.module').then(
        (m) => m.CrmRoutingModule
      ),
  },
  {
    path: 'prism/config',
    loadChildren: () =>
      import('./views/pages/prism-config/prism-config-routing.module').then(
        (m) => m.PrismConfigRoutingModule
      ),
  },
  {
    path: 'prism/crm-customers',
    loadChildren: () =>
      import('./views/pages/customer360/customer360-routing.module').then(
        (m) => m.Customer360RoutingModule
      ),
  },
  {
    path: 'prism/reviews-rating',
    loadChildren: () =>
      import(
        './views/pages/customer-reviews/customer-reviews-routing.module'
      ).then((m) => m.CustomerReviewsRoutingModule),
  },
  {
    path: 'prism/automated-campaigns',
    loadChildren: () =>
      import(
        './views/pages/automated-campaigns/automated-campaigns-routing.module'
      ).then((m) => m.AutomatedCampaignsRoutingModule),
  },
  {
    path: 'prism/campaigns',
    loadChildren: () =>
      import('./views/pages/campaigns/campaigns-routing.module').then(
        (m) => m.CampaignsRoutingModule
      ),
  },
  {
    path: 'prism/nps-module',
    loadChildren: () =>
      import('./views/pages/nps/nps-routing.module').then(
        (m) => m.NpsRoutingModule
      ),
  },
  {
    path: 'prism/customer-interaction',
    loadChildren: () =>
      import(
        './views/pages/customer-intercation/customer-intercation-routing.module'
      ).then((m) => m.CustomerIntercationRoutingModule),
  },
  {
    path: 'prism/campaigns/segments',
    loadChildren: () =>
      import('./views/pages/create-campaign/create-campaign-routing.module').then(
        (m) => m.CreateCampaignRoutingModule
      ),
  },
  {
    path: 'admin/crm-admin',
    loadChildren: () =>
      import('./views/pages/crm-admin/crm-admin-routing.module').then(
        (m) => m.CrmAdminRoutingModule
      ),
  },
  {
    path: 'admin/sales-admin',
    loadChildren: () =>
      import('./views/pages/sales-admin/sales-admin-routing.module').then(
        (m) => m.SalesAdminRoutingModule
      ),
  },
  {
    path: 'admin/owner-access',
    loadChildren: () =>
      import('./views/pages/owner-access/owner-access-routing.module').then(
        (m) => m.OwnerAccessRoutingModule
      ),
  },
  {
    path: 'admin/onboarding-approval',
    loadChildren: () =>
      import(
        './views/pages/onboarding-approval/onboarding-approval-routing.module'
      ).then((m) => m.OnboardingApprovalRoutingModule),
  },
  {
    path: 'admin/finance-management',
    loadChildren: () =>
      import(
        './views/pages/finance-management/finance-management-routing.module'
      ).then((m) => m.FinanceManagementRoutingModule),
  },
  {
    path: 'admin/image-master',
    loadChildren: () =>
      import('./views/pages/image-master/image-master-routing.module').then(
        (m) => m.ImageMasterRoutingModule
      ),
  },
  {
    path: 'admin/flash-admin',
    loadChildren: () =>
      import('./views/pages/flash-admin/flash-admin-routing.module').then(
        (m) => m.FlashAdminRoutingModule
      ),
  },
  {
    path: 'admin/order-support',
    loadChildren: () =>
      import('./views/pages/order-support/order-support-routing.module').then(
        (m) => m.OrderSupportRoutingModule
      ),
  },
  {
    path: 'edge/dashboard',
    loadChildren: () =>
      import('./views/pages/dashboard/dashboard-routing.module').then(
        (m) => m.DashboardRoutingModule
      ),
  },
  {
    path: 'edge/reports',
    loadChildren: () =>
      import(
        './views/pages/analytic-report/analytic-report-routing.module'
      ).then((m) => m.AnalyticReportRoutingModule),
  },
  {
    path: 'edge/outlet-audit',
    loadChildren: () =>
      import('./views/pages/menu-audit/menu-audit-routing.module').then(
        (m) => m.MenuAuditRoutingModule
      ),
  },
  {
    path: 'flash/timelines',
    loadChildren: () =>
      import('./views/pages/flash-timeline/analytics-dashboard-routing.module').then(
        (m) => m.AnalyticsDashboardRoutingModule
      ),
  },
  // {
  //   path: 'edge/menu-management',
  //   loadChildren: () =>
  //     import(
  //       './views/pages/menu-management/menu-management-routing.module'
  //     ).then((m) => m.MenuManagementRoutingModule),
  // },
  {
    path: 'edge/promoManagement',
    loadChildren: () =>
      import(
        './views/pages/promo-management/promo-management-routing.module'
      ).then((m) => m.PromoManagementRoutingModule),
  },
  {
    path: 'edge/payments',
    loadChildren: () =>
      import('./views/pages/payments/payments-routing.module').then(
        (m) => m.PaymentsRoutingModule
      ),
  },
  {
    path: 'edge/outlet-downtime',
    loadChildren: () =>
      import(
        './views/pages/outlet-downtime/outlet-downtime-routing.module'
      ).then((m) => m.OutletDowntimeRoutingModule),
  },
  {
    path: 'corporate/feed-management',
    loadChildren: () =>
      import(
        './views/pages/feed-management/feed-management-routing.module'
      ).then((m) => m.FeedManagementRoutingModule),
  },
  {
    path: 'edge/customer-support',
    loadChildren: () =>
      import(
        './views/pages/customer-support/customer-support-routing.module'
      ).then((m) => m.CustomerSupportRoutingModule),
  },
  {
    path: 'edge/imageManagement',
    loadChildren: () =>
      import(
        './views/pages/image-management/image-management-routing.module'
      ).then((m) => m.ImageManagementRoutingModule),
  },
  {
    path: 'edge/maps-locality',
    loadChildren: () =>
      import('./views/pages/maps/maps-routing.module').then(
        (m) => m.MapsRoutingModule
      ),
  },
  {
    path: 'corporate/blog-manager',
    loadChildren: () =>
      import('./views/pages/blogs/blogs-routing.module').then(
        (m) => m.BlogsRoutingModule
      ),
  },
  {
    path: 'edge/leadGenerationForm',
    loadChildren: () =>
      import(
        './views/pages/lead-generation/lead-generation-routing.module'
      ).then((m) => m.LeadGenerationRoutingModule),
  },
  {
    path: 'corporate/storeManagement',
    loadChildren: () =>
      import(
        './views/pages/store-management/store-management-routing.module'
      ).then((m) => m.StoreManagementRoutingModule),
  },
  {
    path: 'corporate/web_config',
    loadChildren: () =>
      import('./views/pages/web-config/web-config-routing.module').then(
        (m) => m.WebConfigRoutingModule
      ),
  },
  {
    path: 'edge/prebuy-management',
    loadChildren: () =>
      import('./views/pages/prebuy/prebuy-routing.module').then(
        (m) => m.PrebuyRoutingModule
      ),
  },
  {
    path: 'edge/table-management',
    loadChildren: () =>
      import(
        './views/pages/table-management/table-management-routing.module'
      ).then((m) => m.TableManagementRoutingModule),
  },
  {
    path: 'edge/events',
    loadChildren: () =>
      import('./views/pages/events/events-routing.module').then(
        (m) => m.EventsRoutingModule
      ),
  },
  {
    path: 'edge/manage-documentation',
    loadChildren: () =>
      import(
        './views/pages/manage-documentation/manage-documentation-routing.module'
      ).then((m) => m.ManageDocumentationRoutingModule),
  },
  {
    path: 'edge/bulk-inventory-update',
    loadChildren: () =>
      import('./views/pages/bulk-inventory/bulk-inventory-routing.module').then(
        (m) => m.BulkInventoryRoutingModule
      ),
  },
  {
    path: 'edge/invoices',
    loadChildren: () =>
      import('./views/pages/invoices/invoices-routing.module').then(
        (m) => m.InvoicesRoutingModule
      ),
  },
  {
    path: 'edge/mess-flow',
    loadChildren: () =>
      import('./views/pages/mess-flow/mess-flow-routing.module').then(
        (m) => m.MessFlowRoutingModule
      ),
  },
  {
    path: 'edge/request-inventory',
    loadChildren: () =>
      import(
        './views/pages/request-inventory/request-inventory-routing.module'
      ).then((m) => m.RequestInventoryRoutingModule),
  },
  {
    path: 'edge/staffManagement',
    loadChildren: () =>
      import(
        './views/pages/staff-management/staff-management-routing.module'
      ).then((m) => m.StaffManagementRoutingModule),
  },
  {
    path: 'edge/appointment',
    loadChildren: () =>
      import('./views/pages/appointment/appointment-routing.module').then(
        (m) => m.AppointmentRoutingModule
      ),
  },
  {
    path: 'edge/serviceManagement',
    loadChildren: () =>
      import(
        './views/pages/service-management/service-management-routing.module'
      ).then((m) => m.ServiceManagementRoutingModule),
  },
  {
    path: 'edge/enquiry-form',
    loadChildren: () =>
      import('./views/pages/enquiry/enquiry-routing.module').then(
        (m) => m.EnquiryRoutingModule
      ),
  },
  {
    path: 'edge/enquiry-form/:id',
    loadChildren: () =>
      import('./views/pages/enquiry/enquiry-routing.module').then(
        (m) => m.EnquiryRoutingModule
      ),
  },
  {
    path: 'edge/expense-management',
    loadChildren: () =>
      import(
        './views/pages/expense-management/expense-management-routing.module'
      ).then((m) => m.ExpenseManagementRoutingModule),
  },
  {
    path: 'edge/membership-management',
    loadChildren: () =>
      import(
        './views/pages/membership-management/membership-management-routing.module'
      ).then((m) => m.MembershipManagementRoutingModule),
  },
  {
    path: 'edge/package-management',
    loadChildren: () =>
      import(
        './views/pages/package-management/package-management-routing.module'
      ).then((m) => m.PackageManagementRoutingModule),
  },
  {
    path: 'edge/pending-payout',
    loadChildren: () =>
      import('./views/pages/pending-payout/pending-payout-routing.module').then(
        (m) => m.PendingPayoutRoutingModule
      ),
  },
  {
    path: 'edge/inventory-management',
    loadChildren: () =>
      import(
        './views/pages/salon-inventory/salon-inventory-routing.module'
      ).then((m) => m.SalonInventoryRoutingModule),
  },
  {
    path: 'edge/google-reservation',
    loadChildren: () =>
      import(
        './views/pages/google-reservation/google-reservation-routing.module'
      ).then((m) => m.GoogleReservationRoutingModule),
  },
  {
    path: 'prism/loyaltyDashboard',
    loadChildren: () =>
      import('./views/pages/loyalty/loyalty-routing.module').then(
        (m) => m.LoyaltyRoutingModule
      ),
  },
  {
    path: 'edge/loyalty-cards',
    loadChildren: () =>
      import('./views/pages/loyalty-cards/loyalty-cards-routing.module').then(
        (m) => m.LoyaltyCardsRoutingModule
      ),
  },
  {
    path: 'prism/reservation',
    loadChildren: () =>
      import('./views/pages/reseravtion/reseravtion-routing.module').then(
        (m) => m.ReseravtionRoutingModule
      ),
  },
  {
    path: 'edge/order',
    loadChildren: () =>
      import('./views/pages/order/order-routing.module').then(
        (m) => m.OrderRoutingModule
      ),
  },
  {
    path: 'edge/order/:id',
    loadChildren: () =>
      import('./views/pages/order/order-routing.module').then(
        (m) => m.OrderRoutingModule
      ),
  },
  {
    path: 'flash/flash-task',
    loadChildren: () =>
      import('./views/pages/flash-task/flash-task-routing.module').then(
        (m) => m.FlashTaskRoutingModule
      ),
  },
  {
    path: 'admin/crm-sales',
    loadChildren: () =>
      import('./views/pages/crm-sales/crm-sales-routing.module').then(
        (m) => m.CrmSalesRoutingModule
      ),
  },
  {
    path: 'admin/job-posting',
    loadChildren: () =>
      import('./views/pages/job-posting/job-posting-routing.module').then(
        (m) => m.JobPostingRoutingModule
      ),
  },
  {
    path: 'edge/request-management',
    loadChildren: () =>
      import(
        './views/pages/request-management/request-management-routing.module'
      ).then((m) => m.RequestManagementRoutingModule),
  },
  {
    path: 'admin/whatsapp-requests',
    loadChildren: () =>
      import('./views/pages/whatsapp-admin/whatsapp-admin-routing.module').then(
        (m) => m.WhatsappAdminRoutingModule
      ),
  },
  // {
  //   path: 'edge/stories',
  //   loadChildren: () =>
  //     import('./views/pages/stories/stories-routing.module').then(
  //       (m) => m.StoriesRoutingModule
  //     ),
  // },
  {
    path: 'edge/stories',
    loadChildren: () =>
      import('./views/pages/stories1/stories-routing.module').then(
        (m) => m.StoriesRoutingModule
      ),
  },
  {
    path: 'admin/business-management',
    loadChildren: () =>
      import(
        './views/pages/business-management/business-management-routing.module'
      ).then((m) => m.BusinessManagementRoutingModule),
  },
  {
    path: 'corporate/on-call-support',
    loadChildren: () =>
      import('./views/pages/oncall-orders/oncall-orders-routing.module').then(
        (m) => m.OncallOrdersRoutingModule
      ),
  },
  {
    path: 'admin/employee',
    loadChildren: () =>
      import('./views/pages/employee/employee-routing.module').then(
        (m) => m.EmployeeRoutingModule
      ),
  },
  // { path: 'edge/games', loadChildren: () => import('./views/pages/games/games-routing.module').then(m => m.GamesModule) },
  { path: 'edge/advance-payments', loadChildren: () => import('./views/pages/advance-payments/advance-payments.module').then(m => m.AdvancePaymentsModule) },
  { path: 'edge/service-reminder', loadChildren: () => import('./views/pages/service-reminder/service-reminder.module').then(m => m.ServiceReminderModule) },
  { path: 'edge/ho-module', loadChildren: () => import('./views/pages/ho-feed-mang/ho-feed-mang-routing.module').then(m => m.HoFeedMangRoutingModule) },
  { path: 'edge/ondc-dashboard', loadChildren: () => import('./views/pages/ondc-payments/ondc-payments-routing.module').then(m => m.OndcPaymentsRoutingModule) },
  { path: 'admin/ondc-reconcilation', loadChildren: () => import('./views/pages/ondc-reconcilation/ondc-reconcilation-routing.module').then(m => m.OndcReconcilationRoutingModule) },
  { path: 'edge/menu-management', loadChildren: () => import('./views/pages/menu-management/menu-management-new-routing.module').then(m => m.MenuManagementNewRoutingModule) },
  { path: 'corporate/sales-data', loadChildren: () => import('./views/pages/sales-data/sales-data-routing.module').then(m => m.SalesDataRoutingModule) },
  { path: 'prism/rewards', loadChildren: () => import('./views/pages/rewards/rewards-routing.module').then(m => m.RewardsRoutingModule) },
  { path: 'corporate/master-menu', loadChildren: () => import('./views/pages/master-menu/master-menu-routing.module').then(m => m.MasterMenuRoutingModule) },
  // { path: 'corporate/ucollab', loadChildren: () => import('./views/pages/u-collab/u-collab-routing.module').then(m => m.UCollabRoutingModule) },
  { path: 'admin/ondc-funnel', loadChildren: () => import('./views/pages/ondc-funnel/ondc-funnel-routing.module').then(m => m.OndcFunnelRoutingModule) },
  // { path: 'edge/dashboard1', loadChildren: () => import('./views/pages/edge-dashboard/edge-dashboard-routing.module').then(m => m.EdgeDashboardRoutingModule) },
  { path: 'corporate/announcements', loadChildren: () => import('./views/pages/announcements-module/announcements-module-routing.module').then(m => m.AnnouncementsModuleRoutingModule) },
  { path: 'edge/memebership-cards', loadChildren: () => import('./views/pages/loyalty-cards/loyalty-cards-routing.module').then(m => m.LoyaltyCardsRoutingModule) },
  { path: 'flash/live-rider-view', loadChildren: () => import('./views/pages/live-rider-view/live-rider-view-routing.module').then(m => m.LiveRiderViewRoutingModule) },
  { path: 'flash/3pl/config', loadChildren: () => import('./views/pages/threepl-config/threepl-config-routing.module').then(m => m.ThreeplConfigRoutingModule) },
  { path: 'flash/tracking/config', loadChildren: () => import('./views/pages/tracking-config/tracking-config-routing.module').then(m => m.TrackingConfigRoutingModule) },
  { path: 'flash/pos/config', loadChildren: () => import('./views/pages/pos-config/pos-config-routing.module').then(m => m.PosConfigRoutingModule) },
  { path: 'flash/rider/attendance', loadChildren: () => import('./views/pages/rider-attendance/rider-attendance.module').then(m => m.RiderAttendanceModule) },
  { path: 'admin/support', loadChildren: () => import('./views/pages/non-ondc-support/non-ondc-support-routing.module').then(m => m.NonOndcSupportRoutingModule) },
  { path: 'edge/incentives', loadChildren: () => import('./views/pages/incentives/incentives-routing.module').then(m => m.IncentivesRoutingModule) },
  { path: 'prism/whatsapp', loadChildren: () => import('./views/pages/whatsapp-module/whatsapp-module-routing.module').then(m => m.WhatsappModuleRoutingModule) },
  { path: 'edge/membership', loadChildren: () => import('./views/pages/membership/membership-routing.module').then(m => m.MembershipRoutingModule) },
  { path: 'prism/links', loadChildren: () => import('./views/pages/invoice-link-module/invoice-link-module-routing.module').then(m => m.InvoiceLinkModuleRoutingModule) },
  { path: 'prism/links/intro', loadChildren: () => import('./views/pages/invoice-link-module/invoice-link-module-routing.module').then(m => m.InvoiceLinkModuleRoutingModule) },
  { path: 'corporate/ondc-admin', loadChildren: () => import('./views/pages/ondc-admin/ondc-admin-routing.module').then(m => m.OndcAdminRoutingModule) },
  { path: 'prism/rewards-games', loadChildren: () => import('./views/pages/games/games-routing.module').then(m => m.GamesRoutingModule) },
  { path: 'edge/service-reminder', loadChildren: () => import('./views/pages/service-reminder/service-reminder-routing.module').then(m => m.ServiceReminderRoutingModule) },
  { path: 'edge/advance-payments', loadChildren: () => import('./views/pages/advance-payments/advance-payments.module').then(m => m.AdvancePaymentsModule) },
  { path: 'edge/subscription', loadChildren: () => import('./views/pages/subscription/subscription-routing.module').then(m => m.SubscriptionRoutingModule) },
  { path: 'edge/userProfile', loadChildren: () => import('./views/pages/user-profile/user-profile-routing.module').then(m => m.UserProfileRoutingModule) },
  { path: 'flash/flash-developer-api', loadChildren: () => import('./views/pages/flash-developer-api/flash-developer-api.module').then(m => m.FlashDeveloperApiModule) },
  { path: 'download-summary', loadChildren: () => import('./views/pages/download-summary/download-summary.module').then(m => m.DownloadSummaryModule) },
  { path: 'flash/flash-analytics-reports', loadChildren: () => import('./views/pages/flash-analytics-reports/flash-analytics-reports.module').then(m => m.FlashAnalyticsReportsModule) },
  { path: 'admin/account-success', loadChildren: () => import('./views/pages/account-success/account-success.module').then(m => m.AccountSuccessModule) },
  { path: 'edge/fixed-package', loadChildren: () => import('./views/pages/fixed-package/fixed-package.module').then(m => m.FixedPackageModule) },
  { path: 'corporate/web-configuration', loadChildren: () => import('./views/pages/web-configuration/web-configuration.module').then(m => m.WebConfigurationModule) },
  { path: 'wla/web-pages', loadChildren: () => import('./views/pages/web-pages/web-pages.module').then(m => m.WebPagesModule) },
  { path: 'flash/customer-address', loadChildren: () => import('./views/pages/customer-address/customer-address.module').then(m => m.CustomerAddressModule) },

  { path: 'corporate/ucollab', loadChildren: () => import('./views/pages/u-collab-summary/u-collab-summary.module').then(m => m.UCollabSummaryModule) },

  { path: 'admin/ucollab-admin', loadChildren: () => import('./views/pages/u-collab-admin/u-collab-admin.module').then(m => m.UCollabAdminModule) },

    { path: 'edge/whatsapp-ordering', loadChildren: () => import('./views/pages/whatsapp-ordering/whatsapp-ordering-routing.module').then(m => m.WhatsappOrderingRoutingModule) },
  // { path: "**", redirectTo: "error/403", pathMatch: "full" },
  { path: '**', component: LogoutComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
